import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { countries } from "../common/utils";
import request from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import Header from "./header";
import Footer from "./NewFooter";
import { Col } from "reactstrap";
import { CheckValue } from "../../views/common/utils"
import Context from "../../api/context";

import phone from "../../images/new-contact/phone.png"
import mail from "../../images/new-contact/mail.png"
import location from "../../images/new-contact/location.png"
export default class NewContactUs extends Component {
	static contextType = Context
	state = {
		value: "",
		label: "",
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		title: "",
		message: "",
		subscribed: false
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevState) {
		if (prevState.facebookLink !== this.context.facebookLink) {
			//this.setState({'facebookLink': this.context.facebookLink});
		}
		if (this.context.twitterLink && this.context.twitterLink !== '') {
			//this.setState({'twitterLink': this.context.twitterLink});
		}
		if (this.context.instagramLink && this.context.instagramLink !== '') {
			//this.setState({'instagramLink': this.context.instagramLink});
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onCheckedChange = e => {
		e.persist();
		this.setState({
			subscribed: e.target.checked
		});
	};

	onCountrySelect = e => {
		e.persist();
		this.setState({
			value: e.target.value,
			label: e.target.value
		});
	};

	ValidateEmail(mail) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		}
		return false;
	}

	gtag_report_conversion(url) {
		var callback = function () {
			if (typeof (url) != 'undefined') {
				window.location = url;
			}
		};
		window.gtag('event', 'conversion', {
			'send_to': 'AW-977323787/_K59CNvxt48DEIuOg9ID',
			'event_callback': callback
		});
		return false;
	};

	onContactSubmit = () => {
		this.gtag_report_conversion('/contact');
		const { first_name, last_name, email } = this.state;
		const data = {
			value: this.state.value,
			label: this.state.label,
			first_name: first_name,
			last_name: last_name,
			email: email,
			phone: this.state.phone,
			title: this.state.title,
			message: this.state.message,
			subscribed: this.state.subscribed
		};

		if (first_name === "") {
			toast.error("Please enter First Name");
		} else if (last_name === "") {
			toast.error("Please enter Surname");
		} else if (email === "") {
			toast.error("Please enter Email");
		} else if (this.ValidateEmail(email) === false) {
			toast.error("You have entered an invalid email address!");
		} else {
			request({
				url: "/users/contactus/save",
				method: "POST",
				data: data
			})
				.then(res => {
					if (res.status === 1) {
						// this.props.history.push("/");
						// toast.success("Registration Completed Successfully! Please Check Mail");
						// toast.success("Contact Sent Successfully!");
						toast.success(res.response);
						setTimeout(() => {
							this.setState({
								value: "",
								label: "",
								first_name: "",
								last_name: "",
								email: "",
								phone: "",
								title: "",
								message: "",
								subscribed: false
							});
							this.componentDidMount();
						}, 500);
					} else if (res.status === 0) {
						toast.error("Error");
					}
				})
				.catch(err => {
					toast.error(err);
				});
		}
	};
	render() {
		const facebookLink = (this.context.facebookLink && this.context.facebookLink !== '') ? this.context.facebookLink : "";
		const twitterLink = (this.context.twitterLink && this.context.twitterLink !== '') ? this.context.twitterLink : "";
		const instagramLink = (this.context.instagramLink && this.context.instagramLink !== '') ? this.context.instagramLink : "";
		return (
			<>
				<Header></Header>
				{/* <section className="breadcum-headers">
					<div className="container">
						<Col md="12">
							<div className="breadcum-sec">
								<ul>
									<li>
										<Link to="/"> Home </Link>
									</li>
									<li className="active">
										<Link to="/contact"> Contact Us </Link>
									</li>
								</ul>
							</div>
						</Col>
					</div>
				</section> */}
				{/* <section className="abs-contact">
					<img src="../../../img/front/contact-banner.svg" alt="img" />
				</section> */}
				<ToastContainer position="top-right" autoClose={2500} closeOnClick />
				<section className="contact-sections">
					<div className="container">
						<div className="conts-parts">
							<div className="contact-var">
								<p> Do you have more questions about Get Shifts?</p>
								<h2><span>Say Hi!</span> and leave us a message</h2>
								<p>Our team will contact you as soon as possible</p>
								<div className="contact-lists">
									<div className="contact-lists-box">
										<h6>Contact Information</h6>
										<div className="loc_mail_phone">
											<a href="https://maps.app.goo.gl/1MF8wajQdSpwumbK8" target="_blank">
												<img src={location} alt="location" /> <p>Unit 5, Christie Fields Office Park, Christie Way,  Manchester M21 7QY</p>
											</a>
											<a href="mailto:support@getshifts.co.uk">
												<img src={mail} alt="mail" />
												<p>support@getshifts.co.uk</p>
											</a>
											<a href="tel:0330 808 3871">
												<img src={phone} alt="phone" />
												<p>0330 808 3871</p>
											</a>
										</div>
									</div>

									<div className="new_contact_form">
										<div className="form-group">
											<div className={this.state.first_name && CheckValue(this.state.first_name) ? "cms-contact valueAdded" : "cms-contact"}>
												<label>First Name</label>
												<input
													type="text"
													autoComplete="off"
													name="first_name"
													value={this.state.first_name}
													onChange={this.onChange}
													className="form-control"
													required
												/>

											</div>
										</div>
										<div className="form-group">
											<div className={this.state.last_name && CheckValue(this.state.last_name) ? "cms-contact valueAdded" : "cms-contact"}>
												<label>Last Name</label>
												<input
													type="text"
													autoComplete="off"
													name="last_name"
													value={this.state.last_name}
													onChange={this.onChange}
													className="form-control"
													required
												/>

											</div>

										</div>
										<div className="form-group">

											<div className={this.state.email && CheckValue(this.state.email) ? "cms-contact valueAdded" : "cms-contact"}>
												<label> Email Address </label>
												<input
													type="text"
													name="email"
													autoComplete="off"
													value={this.state.email}
													onChange={this.onChange}
													className="form-control"
													required
												/>

											</div>
										</div>
										<div className="form-group">
											<div className={this.state.phone && CheckValue(this.state.phone) ? "cms-contact valueAdded" : "cms-contact"}>
												<label> Phone Number </label>
												<input
													type="number"
													name="phone"
													value={this.state.phone}
													onChange={this.onChange}
													className="form-control"
													required
												/>

											</div>
										</div>
										<div className="form-group">
											<div className={this.state.title && CheckValue(this.state.title) ? "cms-contact valueAdded" : "cms-contact"}>
												<label> Subject </label>
												<input
													type="textarea"
													name="title"
													value={this.state.title}
													className="form-control"
													onChange={this.onChange}
												/>

											</div>
										</div>
										<div className="form-group">
											<div className={this.state.message && CheckValue(this.state.message) ? "cms-contact valueAdded" : "cms-contact"}>
												<label> Message </label>
												<textarea
													type="command"
													name="message"
													value={this.state.message}
													onChange={this.onChange}
													className="form-control"
												></textarea>

											</div>

										</div>
									</div>

								</div>
								<label className="cus-check">
									{" "}
									I want to receive updates from Get Shifts for product
									updates, services and events. By clicking submit you agree to
									our <Link to="#"> Terms of Service </Link> and{" "}
									<Link to="/privacypolicy"> Privacy Policy </Link>
									<input type="checkbox" onChange={this.onCheckedChange} />
									<span className="cus-checkmark"></span>
								</label>
								<button type="submit" className="btn contact-subs" onClick={this.onContactSubmit}>
									{" "}
									Submit{" "}
								</button>
							</div>

						</div>
					</div>
				</section>
				<section className="mapping-contact">
										<div className="container">
										<div className="map-lists">
						<img src="https://maps.googleapis.com/maps/api/staticmap?center=Unit+5+Christie+Fields+Office+Park+Christie+Way+Manchester+M217QY&zoom=13&size=670x200&scale=2&maptype=roadmap&markers=size:mid%7Ccolor:purple%7Clabel:A%7CUnit+5+Christie+Fields+Office+Park+Christie+Way+Manchester+M217QY&key=AIzaSyA9ZhUzjv9YErGJHB2669qt4aq2HdG-DZA" className="contact-banner" alt="img" />
					</div>
					</div>
									</section>
				<Footer></Footer>
			</>
		);
	}
}
