import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import Header from "./header";
import Footer from './NewFooter';
import WOW from '../../animation/Wow';
import { openPopupWidget } from "react-calendly";
import resources1 from "../../images/new-resources/resources1.png"
import resources2 from "../../images/new-resources/resources2.png"
import resources3 from "../../images/new-resources/resources3.png"
import resources4 from "../../images/new-resources/resources4.png"
import resources5 from "../../images/new-resources/resources5.png"
import resourcesicon1 from "../../images/new-resources/roadmap.png"
import resourcesicon2 from "../../images/new-resources/help.png"
import resourcesicon3 from "../../images/new-resources/faq.png"
import resourcesicon4 from "../../images/new-resources/free-training.png"
import resourcesicon5 from "../../images/new-resources/blog.png"

let pageSettings = {
	backgroundColor: 'ffffff',
	hideEventTypeDetails: false,
	hideLandingPageDetails: false,
	primaryColor: '00a2ff',
	textColor: '4d5055'
},
	// url = 'https://calendly.com/getshifts',
	url = 'https://calendly.com/d/cks-3hf-fbt/demo?month=2022-02',
	prefill = {},
	utm = {};
export default class ReSources extends Component {

	componentDidMount() {
		new WOW().init();
	}
	render() {
		return (
			<>
				<Header></Header>
				{/* <section className="breadcum-headers">
					<div className="container">
						<Col md="12">
							<div className="breadcum-sec">
								<ul>
									<li>
										<Link to="#"> Home </Link>
									</li>
									<li className="active">
										<Link to="#"> Resources </Link>
									</li>
								</ul>
							</div>
						</Col>
					</div>
				</section> */}
				{/* <section className="searching-resources">
					<div className="container">
						<div className="search-places">
							<div className="width-ranges">
								<input
									type="text"
									className="form-control"
									placeholder="Search"
									id=""
								/>
								<div className="search-btns">
									<button type="button" className="btn src-bts">
										<img src="../../../img/front/search.svg" alt="images" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<section className="resources-sections">
					<div className="container">
						<div className="res-ranges">
							
							<div className="list-reso">
								<div className="same-ress">
									<div class='card slide-up help_center'>
										<img src={resourcesicon2} alt="Help Center" />
										<h3>Help Center</h3>
										<div class='card-slider'>
											<img src={resourcesicon2} alt="Help Center" />
											<h4>Help Center</h4>
											<p>	Learn how Get Shifts can help you manage your temporary
												workforce.</p>
											<h6 className="learn-more">
											{/* <Link to={{ pathname: "https://agencyportal.portal.trainn.co/" }} target="_blank" > More <i className="fa fa-angle-right" aria-hidden="true"></i> </Link> */}
											<Link to="/helpcentre" target="_blank" > More <i className="fa fa-angle-right" aria-hidden="true"></i> </Link>
											</h6>
										</div>
									</div>
								</div>
							</div>
							<div className="list-reso">
								<div className="same-ress">
									<div class='card slide-up faq'>
										<img src={resourcesicon3} alt="FAQ" />
										<h3>FAQ</h3>
										<div class='card-slider'>
											<img src={resourcesicon3} alt="FAQ" />
											<h4>FAQ</h4>
											<p>	Quickly find answers by referring to our list of commonly
												asked questions.</p>
											<h6 className="learn-more">
												<Link to="/faq"> More <i className="fa fa-angle-right" aria-hidden="true"></i> </Link>
											</h6>
										</div>
									</div>
								</div>
							</div>
							<div className="list-reso">
								<div className="same-ress">
									<div class='card slide-up blog' >
										<img src={resourcesicon5} alt="Blog" />
										<h3>Blog</h3>
										<div class='card-slider'>
											<img src={resourcesicon5} alt="Blog" />
											<h4>Blog</h4>
											<p>	Keep up with the latest feature updates, tips, and best
												practices.</p>
											<h6 className="learn-more">
												<a href=" https://www.getshifts.co.uk/blog/" > More <i className="fa fa-angle-right" aria-hidden="true"></i> </a>
											</h6>
										</div>
									</div>
								</div>
							</div>
							<div className="list-reso">
								<div className="same-ress">
									<div class='card slide-up free_training'>
										<img src={resourcesicon4} alt="Free Training" />
										<h3>Free Training</h3>
										<div class='card-slider'>
											<img src={resourcesicon4} alt="Free Training" />
											<h4>Free Training</h4>
											<p>	Simple and easy steps to get you started with Get Shifts.
												Manage your temporary workforce.</p>
											<h6 className="learn-more">
												<Link to="" onClick={() => openPopupWidget({ url, prefill, pageSettings, utm })}> Book <i className="fa fa-angle-right" aria-hidden="true"></i> </Link>
											</h6>
										</div>
									</div>
								</div>
							</div>
							<div className="list-reso">
								<div className="same-ress">
									<div class='card slide-up roadmap'>
										<img src={resourcesicon1} alt="roadmap" />
										<h3>Product Roadmap</h3>
										<div class='card-slider'>
											<img src={resourcesicon1} alt="roadmap" />
											<h4>Product Roadmap</h4>
											<p>Keep track of what we are working on upcoming features, fixes, requests and enhancements.</p>
											<h6 className="learn-more">
												<Link to={{ pathname: "https://trello.com/b/IVbK9pwF/agency-portal-roadmap" }} target="_blank" >
													More <i className="fa fa-angle-right" aria-hidden="true"></i> </Link>
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer></Footer>
			</>
		);
	}
}
