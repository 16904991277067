import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { ReactTitle } from "react-meta-tags";
import { ToastContainer } from "react-toastify";
// Styles

import "./../node_modules/font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "./scss/style.scss";
// import "./scss/custom.css";
// Temp fix for reactstrap
// import "./scss/core/_dropdown-menu-right.scss";
import "./scss/skyblue.scss";
import "./scss/responsive.scss";
import "./scss/animation.scss";

import "./views/newWebsite/NewCSS/viewdemo.scss";
import "./views/newWebsite/NewCSS/newcontact.scss";
import "./views/newWebsite/NewCSS/newresources.scss";
import "./views/newWebsite/NewCSS/pricing.scss";
import "./views/newWebsite/NewCSS/NewFearure.scss";
import "./views/newWebsite/NewCSS/NewHomePage.scss";
import "./views/newWebsite/NewCSS/main.scss";
import "./views/newWebsite/NewCSS/responsive-front.scss";

// import "./scss/employee.scss";

import "./assets/css/main.scss";
import "./assets/css/mediaquery.scss";
// import "./assets/css/pricing.scss";
// import "./assets/css/resources.scss";

// import aboutus from "./views/website/aboutus";

import NewHomePage from "./views/newWebsite/NewHomePage";
import NewContactUs from "./views/newWebsite/NewContactUs";
import ReSources from "./views/newWebsite/ReSources";
import NewSignUp from "./views/newWebsite/NewSignUp";
import NewPrice from "./views/newWebsite/NewPrice";
import NewPrivacyPolicy from "./views/newWebsite/NewPrivacyPolicy";
import NewFeature from "./views/newWebsite/NewFeature";
import TermsServices from "./views/newWebsite/TermsServices";
import AntispamPolicy from "./views/newWebsite/AntispamPolicy";
import CookiesPolicy from "./views/newWebsite/CookiesPolicy";
import GdprCompliance from "./views/newWebsite/GdprCompliance";
import NewViewDemo from "./views/newWebsite/NewViewDemo";

import AgencyLogin from "./views/agency/agencylogin";
import agencyforgotpassword from "./views/agency/forgotpassword";

import AgencyLoader from "./views/temp/AgencyLoader";
import ApplicationFeature from "./views/newWebsite/applicationfeature"
import RegisterSuccess from "./views/newWebsite/RegisterSuccess";
import FaqDetails from "./views/newWebsite/faq"

/*  */
import Login from "./views/oldURL/login";
import Register from "./views/oldURL/register";
import Gettoken from "./api/gettoken";

/**New getShifts**/
import GetShiftLogin from "./views/newWebsite/Login";
import GetShiftRegister from "./views/newWebsite/Register";
import GetShiftForgotPassword from "./views/newWebsite/ForgotPassword";
/*  */
import KnowledgeBase from "./views/newWebsite/Knowledge_Base"
import KnowledgeBasevideo from "./views/newWebsite/Knowledge_Base/support_video";

// Features
import ShiftManagements from "./views/newWebsite/Features/Shift_managements";
import Timesheets from "./views/newWebsite/Features/Timesheets";
import Employees from "./views/newWebsite/Features/Employees";
import Invoices from "./views/newWebsite/Features/Invoices";
import Complainces from "./views/newWebsite/Features/Complainces";
import Recuritments from "./views/newWebsite/Features/Recuritments";
import Communication from "./views/newWebsite/Features/Communication";
import Document from "./views/common/document";
import SuccessPage from "./views/common/SuccessPage";
import { openPopupWidget } from "react-calendly";
// Company Terms

const spinner = document.getElementById("spinner_loader");

if (spinner && !spinner.hasAttribute("hidden")) {
	spinner.setAttribute("hidden", "true");
}

ReactDOM.render(
	<>
		{/* <ReactTitle title="Get Shifts" /> */}
		<Gettoken>
			<ToastContainer position="top-right" autoClose={2500} closeOnClick />
			<BrowserRouter>
				<Route
					path="/"
					render={({ location }) => {
						window.gtag('config', 'AW-977323787', {
							page_path: location.pathname + location.search,
						});
					}}
				/>
				<Switch>
					{window.scrollTo(0, 0)}
					<Route exact path="/" name="newhomepage" component={NewHomePage} />
					<Route
						exact
						path="/contact"
						name="newcontactus"
						component={NewContactUs}
					/>
					<Route exact path="/resources" name="resources" component={ReSources} />
					<Route
						exact path="/viewdemo" name="newfeature" component={NewViewDemo} />
					<Route exact path="/pricing" name="pricing" component={NewPrice} />
					<Route exact path="/helpcentre" name="helpcentre" component={KnowledgeBase} />
					<Route exact path="/knowledge/video" name="helpcentrevideo" component={KnowledgeBasevideo} />
					<Route
						exact
						path="/terms-services"
						name="termsservices"
						component={TermsServices}
					/>
					{/* OLD URL  */}
					<Route
						exact
						path="/register"
						name="termsservices"
						component={Register}
					/>
					<Route
						exact
						path="/login"
						name="termsservices"
						component={Login}
					/>
					<Route exact path="/agency/forgotpassword" name="agencyforgotpassword" component={agencyforgotpassword} />
					{/*END OLD URL */}
					<Route
						exact
						path="/anti-spam-policy"
						name="antispampolicy"
						component={AntispamPolicy}
					/>
					<Route
						exact
						path="/cookies-policy"
						name="cookiespolicy"
						component={CookiesPolicy}
					/>
					<Route
						exact
						path="/gdpr-compliance"
						name="gdprcompliance"
						component={GdprCompliance}
					/>

					<Route
						exact
						path="/privacy-policy"
						name="newprivacypolicy"
						component={NewPrivacyPolicy}
					/>
					<Route
						exact
						path="/features"
						name="newfeature"
						component={NewFeature}
					/>

					<Route
						exact
						path="/agency-login"
						name="AgencyLogin"
						component={AgencyLogin}
					/>
					<Route
						exact
						path="/agency/login"
						name="AgencyLogin"
						component={AgencyLoader}
					/>
					<Route
						exact
						path="/agency-register"
						name="newsignup"
						component={NewSignUp}
					/>
					<Route
						exact
						path="/application-feature"
						name="Application Feature"
						component={ApplicationFeature}
					/>
					<Route
						exact
						path="/register-success"
						name="RegisterSuccess"
						component={RegisterSuccess}
					/>
					<Route
						exact
						path="/faq/:slug"
						name="FaqDetails"
						component={FaqDetails}
					/>
					<Route
						exact
						path="/signin"
						name="SignIn"
						component={GetShiftLogin}
					/>
					<Route
						exact
						path="/signup"
						name="SignUp"
						component={GetShiftRegister}
					/>
					<Route
						exact
						path="/forgot-password"
						name="Forgot password"
						component={GetShiftForgotPassword}
					/>
					{/* Features */}
					<Route
						exact
						path="/shift-management-software"
						name="Shift Management Software"
						component={ShiftManagements}
					/>
					<Route
						exact
						path="/timesheet-software"
						name="Timesheet Software"
						component={Timesheets}
					/>
					<Route
						exact
						path="/employee-management-software"
						name="Employee Management Software"
						component={Employees}
					/>
					<Route
						exact
						path="/invoicing-software"
						name="Invoicing Software"
						component={Invoices}
					/>
					<Route
						exact
						path="/compliance-software"
						name="Compliance Software"
						component={Complainces}
					/>
					<Route
						exact
						path="/recruitment-softwares"
						name="Recruitment Softwares"
						component={Recuritments}
					/>
					<Route
						exact
						path="/communication"
						name="Communication"
						component={Communication}
					/>
					<Route
						exact
						path="/document/sign"
						name="Documents"
						component={Document}
					/>
					<Route
						exact
						path="/document/sign/success"
						name="SuccessPage"
						component={SuccessPage}
					/>
					<Route
						exact
						path="*"
						name="PageNotFound"
						component={()=>{
							return <Redirect to='/' />
						}}
					/>

				</Switch>
			</BrowserRouter>
		</Gettoken>
	</>,
	document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();



let pageSettings = {
	backgroundColor: 'ffffff',
	hideEventTypeDetails: false,
	hideLandingPageDetails: false,
	primaryColor: '00a2ff',
	textColor: '4d5055'
},
	// url = 'https://calendly.com/getshifts',
	url = 'https://calendly.com/d/cks-3hf-fbt/demo?month=2022-02',
	prefill = {},
	utm = {};
window.onBookDemo = ()=>{
	openPopupWidget({ url, prefill, pageSettings, utm })
}
