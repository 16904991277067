import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
//import classnames from "classnames";
import Header from './header'
import Footer from './NewFooter'
import WOW from '../../animation/Wow'
import AuthetiCationModal from './AuthetiCationModal'

// Images

import featuremincard1 from '../../images/feature-new/feature-mini-card1.png'
import featuremincard2 from '../../images/feature-new/feature-mini-card2.png'
import shiftmanagement from '../../images/feature-new/shift_management.png'
import timesheets from '../../images/feature-new/timesheets.png'
import employee from '../../images/feature-new/employee.png'
import invoice from '../../images/feature-new/invoice.png'
import compliance from '../../images/feature-new/compliance.png'
import recruitment from '../../images/feature-new/recruitment.png'
import { GetStartCTA, GetStartCTATrail } from './Features'

export default class NewFeature extends Component {
   state = {
      activeTab: '1',
      activeTabs: '1',
      showLoginModal: false,
      autheticateTab: 1
   }
   componentDidMount() {
      new WOW().init()
      window.scrollTo(0, 0)
   }

   toggle = activeTab => {
      this.setState({ activeTab: activeTab })
   }

   toggles = activeTabs => {
      this.setState({ activeTabs: activeTabs })
   }

   openSigninModal = (e, tab) => {
      this.setState({ showLoginModal: true })
      this.setState({ autheticateTab: tab })
   }

   closeSigninModal = e => {
      this.setState({ showLoginModal: false })
   }
   handleButtonClick = buttonNumber => {
      this.setState({ activeButton: buttonNumber })
   }

   render() {
      const { activeButton } = this.state

      const scrollToCenter = id => {
         const element = document.getElementById(id)
         if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
            const offset = window.innerHeight / 2 - element.clientHeight / 2
            window.scrollTo({
               top: elementPosition - offset,
               behavior: 'smooth'
            })
         }
      }

      return (
         <>
            <Header></Header>
            <section className="tabbing-features fea-lists ">
               <div className="container">
                  <div className="feature_head_tittle">
                     <h4>
                        {' '}
                        Get Shifts Is Packed With Features To <br />
                        Improve Your Temporary Staffing{' '}
                     </h4>
                  </div>
                  <div className="tab-lists-features">
                     <div className="tittle_head">
                        <div className="container">
                           <div className="feature_head">
                              <button>
                                 <a
                                    className={activeButton === 1 ? 'active' : ''}
                                    onClick={() => {
                                       this.handleButtonClick(1)
                                       scrollToCenter('shift_management')
                                    }}
                                 >
                                    Shift Management
                                 </a>
                              </button>
                              <button>
                                 <a
                                    className={activeButton === 2 ? 'active' : ''}
                                    onClick={() => {
                                       this.handleButtonClick(2)
                                       scrollToCenter('timesheets')
                                    }}
                                 >
                                    Timesheets
                                 </a>
                              </button>

                              <button>
                                 <a
                                    className={activeButton === 3 ? 'active' : ''}
                                    onClick={() => {
                                       this.handleButtonClick(3)
                                       scrollToCenter('employee')
                                    }}
                                 >
                                    Employee
                                 </a>
                              </button>
                              <button>
                                 <a
                                    className={activeButton === 4 ? 'active' : ''}
                                    onClick={() => {
                                       this.handleButtonClick(4)
                                       scrollToCenter('invoice')
                                    }}
                                 >
                                    Invoice
                                 </a>
                              </button>
                              <button>
                                 <a
                                    className={activeButton === 5 ? 'active' : ''}
                                    onClick={() => {
                                       this.handleButtonClick(5)
                                       scrollToCenter('compliance')
                                    }}
                                 >
                                    Compliance
                                 </a>
                              </button>
                              <button>
                                 <a
                                    className={activeButton === 6 ? 'active' : ''}
                                    onClick={() => {
                                       this.handleButtonClick(6)
                                       scrollToCenter('recruitment')
                                    }}
                                 >
                                    Recruitment
                                 </a>
                              </button>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-12" id="shift_management">
                        <div className="features-strus effortless">
                           <div className="col-lg-5">
                              <div className="cont-featurs">
                                 <h5>Shift Management</h5>
                                 <h6>
                                    {' '}
                                    Effortless <br /> Shift Management{' '}
                                 </h6>
                                 <p>
                                    {' '}
                                    Streamline Staff Scheduling With Get Shifts Shift Management Module; The Central, Most Powerful And Intuitive Module Of The
                                    Get Shifts Platform That Lets You Create, Edit And View All Your Staff Shifts And Schedules Online.
                                 </p>

                                 <Link to="/shift-management-software" className="btn feature_learnmore">
                                    Learn more
                                 </Link>
                              </div>
                           </div>
                           <div className="col-lg-7">
                              <div className="img-features">
                                 <img src={shiftmanagement} alt="img" />
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-12" id="timesheets">
                        <div className="features-strus automated">
                           <div className="col-lg-7">
                              <div className="img-features">
                                 <img src={timesheets} alt="img" />
                              </div>
                           </div>
                           <div className="col-lg-5">
                              <div className="cont-featurs">
                                 <h5>Timesheets</h5>
                                 <h6>
                                    {' '}
                                    Automated Online Approval <br />
                                    <span> Are we ready to ditch the paper? </span>
                                 </h6>
                                 <p>
                                    {' '}
                                    With Automated Online Timesheets, All The Calculations Are Made In Get Shifts, And Since Everyone Can View Their Timesheets
                                    Anywhere, There’s Actually Very Little Need For Paper.
                                 </p>
                                 <Link to="/timesheet-software" className="btn feature_learnmore">
                                    Learn more
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </div>



                     {/* <div className="col-md-12">
                        <div className="free-offers new-get">
                           <div className="sigfr-width">
                              <div className="left-subs">
                                 <img src={featuremincard1} alt="featuremincard1" />
                              </div>
                              <div className="right-subs">
                                 <p>
                                    Discover how Get Shifts can revolutionise your workforce management. Schedule a demo with our team to see how our platform
                                    can streamline your scheduling and improve efficiency.
                                 </p>
                                 <div className="trails-sec">
                                    <Link to="/signup">Get started for free</Link>
                                    <a href="#" onClick={e => this.openSigninModal(e, '2')}> Get Started - <span> It's free </span> </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> */}

                     <GetStartCTA />


                     <div className="col-md-12" id="employee">
                        <div className="features-strus manage-employee">
                           <div className="col-lg-5">
                              <div className="cont-featurs">
                                 <h5>Employee</h5>
                                 <h6>
                                    {' '}
                                    Manage Employees <br /> with ease{' '}
                                 </h6>
                                 <p>
                                    {' '}
                                    Take Control Of Your Entire Staff Database In One Intuitive Portal: View, Edit And Update Staff Details In A Couple Of
                                    Clicks.
                                 </p>
                                 <Link to="/employee-management-software" className="btn feature_learnmore">
                                    Learn more
                                 </Link>
                              </div>
                           </div>
                           <div className="col-lg-7">
                              <div className="img-features">
                                 <img src={employee} alt="img" />
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-12" id="invoice">
                        <div className="features-strus manage-invoice">
                           <div className="col-lg-7">
                              <div className="img-features">
                                 <img src={invoice} alt="img" />
                              </div>
                           </div>
                           <div className="col-lg-5">
                              <div className="cont-featurs">
                                 <h5>Invoice</h5>
                                 <h6> Invoicing </h6>
                                 <p>
                                    {' '}
                                    Generating Invoices Automatically When Timesheets Are Approved Ensures Timely Payments With Less Pain, All In One Platform.
                                 </p>
                                 <Link to="/invoicing-software" className="btn feature_learnmore">
                                    Learn more
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                     <GetStartCTATrail />
                     <div className="col-md-12" id="compliance">
                        <div className="features-strus worry-free">
                           <div className="col-lg-5">
                              <div className="cont-featurs">
                                 <h5>Compliance</h5>
                                 <h6>
                                    {' '}
                                    Worry-free <br /> Compliance{' '}
                                 </h6>
                                 <p>
                                    {' '}
                                    Stay On Top Of Staff Compliance And Enjoy Peace Of Mind With Centralised Record Keeping. With Get Shifts, Your Employees’
                                    Information Is Digitally Stored And Organised.
                                 </p>
                                 <Link to="/compliance-software" className="btn feature_learnmore">
                                    Learn more
                                 </Link>
                              </div>
                           </div>
                           <div className="col-lg-7">
                              <div className="img-features">
                                 <img src={compliance} alt="img" />
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-12" id="recruitment">
                        <div className="features-strus recruitment-free">
                           <div className="col-lg-7">
                              <div className="img-features">
                                 <img src={recruitment} alt="img" />
                              </div>
                           </div>
                           <div className="col-lg-5">
                              <div className="cont-featurs">
                                 <h5>Recruitment</h5>
                                 <h6>Recruitment Tracking</h6>
                                 <p>
                                    {' '}
                                    Our Integrated Recruitment Tracking System Is Designed To Take The Hustle Off Your Recruitment Process, From Application,
                                    Compliance checks, Referencing , Interviews, Through To Staff On-boarding, All In One Powerful and Intuitive System.
                                 </p>
                                 <Link to="/recruitment-softwares" className="btn feature_learnmore">
                                    Learn more
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            {this.state.showLoginModal === true ? (
               <AuthetiCationModal
                  loginModal={this.state.showLoginModal}
                  closeModal={e => this.closeSigninModal(e)}
                  autheticateTab={this.state.autheticateTab}
               />
            ) : null}
            {/* <section className="colors-managements">
                    <div className="container">
                        <div className="system-updates">
                            <div className="col-md-12">
								<div className="">
									
								</div>
							</div>
						</div>
					</div>
				</section> */}
            <Footer></Footer>
         </>
      )
   }
}
